<template>
  <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.72222" cy="2.72222" r="2.72222" fill="#8699B3"/>
    <circle cx="10.8887" cy="2.72222" r="2.72222" fill="#8699B3"/>
    <circle cx="19.0557" cy="2.72222" r="2.72222" fill="#8699B3"/>
</svg>

</template>

<script>
export default {
    name:"MoreInfo"
}
</script>