<template>
  <div>
    <h3>Missing someone from this household?</h3>
    <Button
      variant="dark-blue"
      data-cy="new-family-member-button"
      text="Add a family member"
      @click="showNewMemberModal = true"
    />

    <template v-if="showNewMemberModal">
      <Modal @close="showNewMemberModal = false">
        <template #content>
          <NewFamilyMemberForm @created="handleNewMember" />
        </template>

        <template #button>
          <span></span>
        </template>
      </Modal>
    </template>
  </div>
</template>

<script>
import Button from '@/components/shared/buttons/Button.vue';
import NewFamilyMemberForm from '@/components/people/NewFamilyMemberForm.vue';
import Modal from '@/components/shared/modals/Modal.vue';

export default {
  name: 'NewFamilyMember',

  components: {
    Modal,
    NewFamilyMemberForm,
    Button,
  },

  emits: ['created'],

  data() {
    return {
      showNewMemberModal: false,
    };
  },

  methods: {
    handleNewMember() {
      this.showNewMemberModal = false;
      this.$emit('created');
    },
  },
};
</script>


<style scoped lang="scss">
  ion-content {

    h3 {
      margin-top: 30px;
      margin-bottom: 18px;
      color: rgb(76, 100, 177);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
</style>
