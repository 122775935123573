<template>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <div>
        <Button
          :text="`Check-in attendees (${pendingAttendees.length})`"
          :loading="processing"
          :disabled="pendingAttendees.length === 0"
          :variant="pendingAttendees.length === 0 ? 'gray' : 'primary-bold'"
          data-cy="do-checkin"
          @click="doCheckin"
        />
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import { IonFooter, IonToolbar } from '@ionic/vue';
  import Button from '@/components/shared/buttons/Button.vue';

  export default {
    components: {
      IonFooter,
      IonToolbar,
      Button,
    },

    data() {
      return {
        processing: false,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        service: (state) => state.services.current,
        checkinSession: (state) => state.checkin.session,
        pendingAttendees: (state) => state.checkin.pendingAttendees,
      }),

      sessionStart() {
        if (!this.service) {
          return;
        }

        const date = this.$dayjs(this.service.service_date);
        const [hour, minutes] = this.service.start_time.split(':');

        return date.hour(hour).minute(minutes);
      },

      sessionFinish() {
        if (!this.service) {
          return;
        }

        return this.$dayjs(this.service.service_date).endOf('day');
      },

      serviceIsToday() {
        return this.$dayjs().isSame(this.service.service_date, 'day');
      },
    },

    methods: {
      ...mapActions({
        startSession: 'checkin/startSession',
        checkinAllPending: 'checkin/checkinAllPending',
      }),

      ...mapMutations({
        moveToAttendees: 'checkin/MOVE_TO_ATTENDEES',
      }),

      async doCheckin() {
        if (!this.service) {
          this.showToast('No event selected yet.', 'danger');
          return;
        }

        if (!this.serviceIsToday) {
          this.showToast(
            'The selected event is not scheduled for today.',
            'danger'
          );
          return;
        }

        if (!this.pendingAttendees.length) {
          this.showToast('No attendees selected yet.', 'danger');
          return;
        }

        this.processing = true;

        try {
          if (!this.checkinSession) {
            await this.startSession({
              organizationId: this.user.currentOrganizationId,
              serviceId: this.service.id,
              start: this.sessionStart,
              finish: this.sessionFinish,
            });
          }

          await this.checkinAllPending({
            organizationId: this.user.currentOrganizationId,
            serviceId: this.service.id,
          });

          this.$router.push('/checkin/success');
        } finally {
          if (this.pendingAttendees.length) {
            this.showToast('Some persons were not checked in.', 'danger');
          }

          this.processing = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-footer {
    opacity: 0;
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;
    ion-toolbar {
      --background: #ffffff;
      box-shadow: 0px 0px 12px rgba(191, 191, 191, 0.25);
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 124px;
      }
    }
  }
</style>
