<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 7.35761L7.08936 12.3332L18 1.6665" :stroke="color" stroke-width="2.125" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
    name:"CheckMark",

    props: {
      color: {type: String, default: '#5BD9A4'}
    }
}
</script>