<template>
  <ion-page>
    <ion-content id="main-content" :fullscreen="true">
      <div v-if="loading" class="spinner-container">
        <ion-spinner color="primary" name="lines"></ion-spinner>
      </div>

      <div v-else class="container">
        <FamilyList v-if="members.length" />
        <NewFamilyMember @created="fetchData" />
      </div>
    </ion-content>

    <SearchFooter />
  </ion-page>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
  import { IonSpinner, IonContent, IonPage } from '@ionic/vue';

  import SearchFooter from '@/components/layout/SearchFooter';
  import FamilyList from '@/components/search/lists/FamilyList';
  import NewFamilyMember from '@/components/people/NewFamilyMember.vue';

  export default {
    name: 'Household',

    components: {
      IonSpinner,
      IonContent,
      IonPage,
      SearchFooter,
      FamilyList,
      NewFamilyMember,
    },

    data() {
      return {
        loading: true,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        location: (state) => state.locations.current,
        service: (state) => state.services.current,
        household: (state) => state.households.current,
      }),

      ...mapGetters({
        members: 'households/members',
      }),

      householdId() {
        return this.$route.params.householdId;
      },

      serviceInformation() {
        return this.service ? `${this.service.name} @ ${this.service.start_time}` : 'Loading...';
      },
    },

    async ionViewWillEnter() {
      this.loading = true;

      if (!this.location) {
        this.$router.push({ name: 'Locations' });
      } else if (!this.service) {
        this.$router.push({
          name: 'LocationServices',
          params: { id: this.location.id },
        });
      }

      await this.fetchData();
    },

    ionViewDidLeave() {
      this.clearHousehold();
    },

    methods: {
      ...mapActions({
        fetchHousehold: 'households/show',
      }),

      ...mapMutations({
        addAttendee: 'checkin/ADD_ATTENDEE',
        clearHousehold: 'households/CLEAR',
      }),

      selectAttendee() {
        this.addAttendee();
      },

      async fetchData() {
        this.loading = true;

        await this.fetchHousehold({
          organizationId: this.user.currentOrganizationId,
          householdId: this.householdId,
        });

        this.loading = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-content {

    .spinner-container {
      height: 100vh;
      width: 100%;
      position: absolute !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container {
      min-height: 100%;
      h1 {
        margin-bottom: 24px;
      }
    }
  }
</style>
