<template>
  <div class="list">
    <h2 class="leabel">{{ household.name }} Household ({{ members.length }})</h2>
    <FamilyMemberItem
      v-for="person in members"
      :key="person.id"
      :person="person"
    />
    <FamilyMemberItem :checked="true"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FamilyMemberItem from '@/components/search/lists/FamilyList/partials/FamilyMemberItem';

export default {
  name:"FamilyList",

  components:{
    FamilyMemberItem
  },

  data() {
    return {
      showModal: false,
      selectingPerson: null,
    }
  },

  emits: ['select'],

  computed: {
    ...mapState({
      household: (state) => state.households.current,
    }),

    ...mapGetters({
      members: 'households/members',
    }),
  },

  methods: {
    selectPerson(person) {
      this.selectingPerson = person;
      this.showModal = true;
    },
  },
}
</script>

<style scoped lang="scss">

.list{
    margin-top: 45px;
    text-align: left;
    text-align: left;
    opacity: 0;
    -webkit-animation: .8s ease 0s normal forwards 1 fadein;
    animation: .8s ease 0s normal forwards 1 fadein;
    .leabel{
        margin-bottom: 16px;
    }
    .resultList{
        overflow: scroll;
    }
}
.resultStyle{
    margin-top: -190px;
    opacity: 0;
    transform: translate(0px, 0px);
    -webkit-animation: .5s ease 0s normal forwards 1 fadein;
    animation: .5s ease 0s normal forwards 1 fadein;

}
.footerButton{
    bottom: 0%;
    background: white;
    box-shadow: 0px 0px 12px 0px rgba(191, 191, 191, 0.25);
}
</style>
