<template>
  <div class="add-family-member">
    <h1>Add new family member</h1>
    <Form ref="memberForm" v-slot="{ meta }">
      <Field
        v-slot="{ errors, field }"
        v-model="form.first_name"
        as="div"
        vid="first_name"
        name="First Name"
        rules="required|min:1"
      >
        <Input
          v-bind="field"
          label="First Name"
          type="text"
          placeholder="Your name"
          cy-name="input-first-name"
          :required="true"
          :error="errors[0] || null"
          :capitalize-words="true"
        />
      </Field>

      <Field
        v-slot="{ errors, field }"
        v-model="form.last_name"
        as="div"
        vid="last_name"
        name="Last Name"
        rules="required|min:1"
      >
        <Input
          v-bind="field"
          label="Last name"
          type="text"
          placeholder="Your last name"
          cy-name="input-last-name"
          :required="true"
          :error="errors[0] || null"
          :capitalize-words="true"
        />
      </Field>

      <Field
        v-slot="{ field }"
        v-model="form.gender"
        as="div"
        vid="gender"
        name="Gender"
      >
        <SelectInput
          v-bind="field"
          :options="genderOptions"
          placeholder="Not specified"
          label="Gender"
          cy-name="input-gender"
        />
      </Field>

      <Field
        v-slot="{ errors, field }"
        v-model="form.date_of_birth"
        as="div"
        vid="date_of_birth"
        name="Date of Birth"
      >
        <DatePicker
          v-bind="field"
          label="Date of Birth"
          cy-name="input-date-of-birth"
          :error="errors[0] || null"
        />
      </Field>

      <div>
        <Button
          text="Add Person"
          button-type="submit"
          :loading="form.processing"
          :disabled="form.processing || !meta.valid"
          data-cy="member-submit-button"
          @click="createFamilyMember"
        />
      </div>
    </Form>
  </div>
</template>

<script>
  import { Form, Field } from 'vee-validate';
  import { mapActions, mapState } from 'vuex';

  import Input from '@/components/shared/inputs/Input.vue';
  import SelectInput from '@/components/shared/inputs/SelectInput.vue';
  import DatePicker from '@/components/shared/inputs/DatePicker.vue';
  import Button from '@/components/shared/buttons/Button.vue';

  import capitalizeWords from '@/utils/functions/capitalizeWords';

  export default {
    name: 'NewFamilyMemberForm',

    components: {
      Input,
      SelectInput,
      DatePicker,
      Button,
      Form,
      Field,
    },

    emits: ['created'],

    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          gender: '',
          date_of_birth: null,
          processing: false,
        },
        genderOptions: ['Female', 'Male'],
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),
    },

    methods: {
      ...mapActions({
        storePerson: 'people/store',
        addMemberToCurrentHousehold: 'households/addMemberToCurrent',
      }),

      /**
       * Create new person
       */
      async createFamilyMember() {
        this.form.processing = true;

        try {
          this.form.first_name = capitalizeWords(this.form.first_name)
          this.form.last_name = capitalizeWords(this.form.last_name);

          const person = await this.storePerson({
            orgId: this.user.currentOrganizationId,
            form: this.form,
          });

          await this.attachPersonToCurrentHousehold(person);

          this.$emit('created');
        } catch (err) {
          this.showToast('Something went wrong', 'danger');
        } finally {
          this.form.processing = false;
        }
      },

      /**
       * Attach a person to a household
       */
      attachPersonToCurrentHousehold(person, primary) {
        return this.addMemberToCurrentHousehold({
          organizationId: this.user.currentOrganizationId,
          form: {
            id: person.id,
            primary: !!primary || false,
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .add-family-member {
    h1 {
      font-size: 28px;
      line-height: 1;
      margin-top: 0;
    }

    form {
      margin-top: 20px;
      margin-bottom: 20px;

      > div {
        margin-top: 20px;
      }
    }
  }
</style>
