<template>
  <div v-if="person" class="row" :class="checkedIn ? 'checked' : 'item'">
    <div class="info">
      <ion-checkbox
        v-show="!checkedIn"
        color="primary"
        :checked="selected"
        :data-cy="`checkbox-${person.id}`"
        @ionChange="toggleChecked"
      ></ion-checkbox>
      <template v-if="person.avatar_image && person.avatar_image.length">
        <img class="pic" :src="person.avatar_image" />
      </template>
      <template v-else>
        <div
          class="pic default"
          :style="{ backgroundColor: person.avatar_color }"
        >
          {{ person.first_name.substring(0, 1) }}{{ person.last_name.substring(0, 1) }}
        </div>
      </template>

      <div :data-cy="`info-${person.id}`">
        <h3>{{ person.first_name }} {{ person.last_name }}</h3>
        <p>
          <span v-if="school_grade">{{ getSchoolGradeLabel(school_grade) }}</span><span v-show="checkedIn">• {{ service.name }} @ {{ service.start_time }}</span>
        </p>
      </div>
    </div>
    <ion-button v-if="!checkedIn" fill="outline" @click="showModal = true" :data-cy="`grade-button-${person.id}`">
      <MoreInfo />
    </ion-button>
    <ion-chip v-else>
      <Checkmark />
      <span>Checked-in</span>
    </ion-chip>
  </div>

  <template v-if="showModal">
    <Modal @close="showModal = false" @submit="setGrade">
      <template #content class="modal">
        <template v-if="person.avatar_image && person.avatar_image.length">
          <img class="pic" :src="person.avatar_image" />
        </template>
        <template v-else>
          <div
            class="pic default"
            :style="{ backgroundColor: person.avatar_color }"
          >
            {{ person.first_name.substring(0, 1) }}{{ person.last_name.substring(0, 1) }}
          </div>
        </template>

        <h2>{{ person.first_name }} {{ person.last_name }}</h2>

        <div>
          <ion-item class="ion-no-padding" lines="none">
            <ion-label>Classroom</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding select" lines="none">
            <ion-select
              v-model="school_grade"
              :interface-options="{ header: 'Classroom' }"
            >
              <ion-select-option
                v-for="option in gradeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div>
      </template>

      <template #button="{ submit }">
        <Button
          text="Save Changes"
          :disabled="!school_grade"
          data-cy="save-classroom"
          @click="submit"
        />
      </template>
    </Modal>
  </template>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import {
    IonCheckbox,
    IonChip,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  } from '@ionic/vue';
  import Modal from '@/components/shared/modals/Modal';
  import MoreInfo from '@/components/shared/icons/MoreInfo';
  import Checkmark from '@/components/shared/icons/Checkmark';
  import Button from '@/components/shared/buttons/Button';

  export default {
    name: 'FamilyItem',

    components: {
      Modal,
      MoreInfo,
      IonCheckbox,
      IonChip,
      Checkmark,
      Button,
      IonItem,
      IonLabel,
      IonSelect,
      IonSelectOption,
    },

    props: {
      family: {
        type: Array,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      person: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        showModal: false,
        school_grade: null,
      };
    },

    created() {
      this.school_grade = this.person ? this.person.school_grade : null;
    },

    computed: {
      ...mapState({
        pendingAttendees: (state) => state.checkin.pendingAttendees,
        attendees: (state) => state.checkin.attendees,
        service: (state) => state.services.current,
        gradeOptions: (state) => state.people.gradeOptions,
      }),

      selected() {
        return !!this.pendingAttendees.find((p) => p.id === this.person.id);
      },

      checkedIn() {
        return !!this.attendees.find((p) => p.id === this.person.id);
      },

      ...mapGetters({
        getSchoolGradeLabel: 'people/getSchoolGradeLabel',
      }),
    },

    methods: {
      ...mapMutations({
        addAttendee: 'checkin/ADD_PENDING_ATTENDEE',
        removeAttendee: 'checkin/REMOVE_PENDING_ATTENDEE',
      }),

      setGrade() {
        this.showModal = false;
      },

      toggleChecked(event) {
        if (event.target.checked) {
          this.addAttendee({
            ...this.person,
            school_grade: this.school_grade,
          });
        } else {
          this.removeAttendee(this.person);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #181f36;
    width: 750px;
    height: 86px;
    left: 0px;
    top: 0px;
    padding-inline: 24px;
    margin-bottom: 20px;
    border: 1px solid rgba(80, 95, 115, 0.2);
    box-sizing: border-box;
    border-radius: 12px;

    .info {
      display: flex;
      align-items: center;
      ion-checkbox {
        margin-right: 16px;
      }

      .pic {
        width: 48px;
        height: 48px;
        border-radius: 99999px;

        &.default {
          background-color: #181f36;
          color: white;
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0%;
        margin-inline: 16px;
      }
      p {
        margin: 0px;
        color: #181f36;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        opacity: 80%;
        margin-inline: 16px;
      }
    }
    ion-chip {
      background: rgba(91, 217, 164, 0.1);
      border: 1.5px solid #5bd9a4;
      border-radius: 250px;
      color: rgba(91, 217, 164, 1);
      font-size: 18px;
      padding: 20px 15px;

      span {
        margin-left: 12px;
        font-weight: 500;
      }
    }
    ion-button {
      height: 49px;
      width: 49px;
      --padding-start: 3px;
      --padding-end: 3px;
      --border-color: #e9e9e9;
      --background-activated: rgba(64, 100, 192, 0.7);
    }
  }

  .checked {
    background: rgba(91, 217, 164, 0.1);
    border: 1px solid #5bd9a4;
  }
  .item {
    background: #ffffff;
  }

  .modal {
    .pic {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      &.default {
        background-color: #181f36;
        color: white;
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #181f36;
      margin-bottom: 38px;
    }

    .select {
      margin-bottom: 32px;
    }

    ion-label {
      margin-left: 10px !important;
    }

    ion-select {
      --padding-end: 32px;
      --padding-start: 32px;
      font-size: 14px;
      color: #212a4a;
      background: white !important;
      border-radius: 250px;
      text-align: left;
      width: 600px;
      height: 53px;
      border: 1px solid #e9e9e9 !important;
    }
  }
  @media only screen and (orientation: portrait) {
  .row {
   width: 710px;
  }
}
</style>
